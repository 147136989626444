








































import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory, Listing } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';
import ListingCard from '@/components/ListingCard.vue';
import Loader from '@/components/UI/Loader.vue';
import { API } from 'client-website-ts-library/services';

@Component({
  components: {
    ListingCard,
    Loader,
  },
})
export default class LuxuryPropertiesForRent extends Mixins(View) {
  loading = true;

  listings: Listing[] = [];

  private listingFilter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialRental,
    ],
    PageSize: 100,
  });

  mounted() {
    API.Listings.Search(this.listingFilter).then((data) => {
      this.listings = data.Items.filter((itm) => itm.IsHighlightListing === true);

      this.loading = false;
    });
  }
}
